import * as React from "react";

import { SecondaryPageTitle, SeriousTalk } from "../components/shared/secondary-page-elements";

import DownloadModal from "../components/shared/download-modal";
import Footer from "../components/sections/footer";
import Grid from "../components/shared/grid";
import { H5 } from "../components/shared/headings";
import Layout from "../components/shared/layout";
import Navbar from "../components/navbar";
import { OutboundLink } from "../components/shared/Href";
import Section from "../components/shared/section";
import Seo from "../components/shared/seo";
import { StaticImage } from "gatsby-plugin-image";
import ogImage from "../images/banner.png";
import styled from "styled-components";

const SeriesAAnnouncementPage = () => {
  return (
    <Layout>
        <Seo 
          title={"Series A announcement | Copilot"}
          ogTitle={"Series A announcement | Copilot"}
          image={ogImage}
          description={"Copilot Money raises $6M in Series A funding"}
        />
        <DownloadModal/>
        <Navbar/>
        <Section>
            <Grid>
                <HeaderImage>
                    <StaticImage
                        src="../images/banner.png"
                        layout="fullWidth"
                        quality={100}
                        alt=""
                        placeholder="blurred"
                    />
                </HeaderImage>
              <SecondaryPageTitle>Copilot Money raises $6M in Series A funding</SecondaryPageTitle>
                <SeriousTalk>
                  <p>We started Copilot because we got tired of waiting for someone else to create a smart, private, and delightful personal finance tool that would help us navigate our finances with confidence. We wanted a well-crafted app that would provide full visibility into all things money and would get out of the way. No ads, no gimmicks.</p>
                  <p>Since our launch in 2020, we've made a ton of progress in our mission to empower everyone to be in control of their money. But, there's still so much more left to do. Today, we're announcing that we've raised $6M in Series A funding led by Adjacent to bring Copilot to all platforms and accelerate our AI and product development efforts.</p>
                  <H5>How we got here</H5>
                  <p>Our metrics may give the impression of an overnight success: We've grown more in the last 4 months than in our first 4 years in business. However, growing Copilot's subscriber base to 100,000+ users has been the result of a deeply disciplined small team, a focus on building a great product, and patience.</p>
                  <ChartImage>
                      <StaticImage
                          src="../images/chart.png"
                          layout="fullWidth"
                          quality={100}
                          alt=""
                          placeholder="blurred"
                      />
                  </ChartImage>
                  <blockquote>
                    <p>I'm so happy I found this app, you all are nailing it! I'm so inspired by this UI. What a relief to feel happy while looking at my money.</p>
                    Andy, March 2024
                  </blockquote>
                  <blockquote>
                    <p>Copilot is now an everyday part of my life, and it has truly allowed me to be financially sound and free.</p>
                    Brian, Feb 2024
                  </blockquote>
                  <p>It all started with a team of just one, with me stepping out from Google in 2018. I had moved a long way from Chile more than a decade ago, looking to make a real impact in people's lives through the software I was helping create. Unfortunately, I felt my work was often lost in a maze of projects that would eventually get canceled. I craved something more meaningful.</p>
                  <p>Ironically, while I was helping Google organize the world's information, I realized I wasn't nearly as organized when it came to my own finances. I tried every app out there, but they always fell short – either too clunky or simply inaccurate. That frustration led me to gamble my savings on an idea: to build a finance app that didn't make managing money a chore.</p>
                  <p>Many thought I was diving into an oversaturated market where Mint was already the clear winner. But as Copilot began taking shape, I knew I had something special. My friends were finally using the app out of genuine preference, not as a personal favor. That's when I knew it was time to scale up. I brought in Gabriel, a brilliant friend from my Engineering school days, and together we launched the app publicly in January of 2020.</p>
                  <blockquote>
                    <p>This app has massive potential. I've been waiting forever for an alternative to the underwhelming options. Thank you!!</p>
                    Michelle, Jan 2020
                  </blockquote>
                  <blockquote>
                    <p>Love love love your app! I've gone from Mint to Personal Capital to many others and none of them could do it. Glad you guys are finally here. You guys will be huge.</p>
                    Abdu, Jan 2020
                  </blockquote>
                  <p>Fast forward a few years, and thanks to the hard work of a growing team and the launch of our desktop app, Copilot achieved profitability in 2023. A dream come true for any startup founder.</p>
                  <p>So why raise capital? Mint's closure has opened up exciting new opportunities in the personal finance space. Many people are now searching for a more intuitive, privacy-focused financial tool, and they're turning to us. Our goal now extends beyond just catering to iPhone or Mac users; we're on a mission to make Copilot the go-to financial hub for everyone, everywhere. This infusion of capital helps us accelerate our Web and Android efforts while maintaining the same level of engineering quality and attention to detail that our users have come to expect.</p>
                  <p>We're also doubling down on our investment in AI. Copilot already employs Machine Learning to personalize spending categorization, significantly saving users' time by cutting the number of times transactions need to be manually re-categorized in half. But, there's so much more we want to offer. We believe that a thoughtful approach to AI can have a massive positive impact on our users' finances. The opportunities ahead of us are really exciting. Now, we have the resources to go after them.</p>
                  <H5>Partnering with Adjacent and Exceptional Operators</H5>
                  <p>Connecting with Nico Wittenborn a few months ago was a defining moment. Over the years, I've had the chance to chat with many in the venture capital world but talking to Nico was different. His immediate understanding of Copilot's mission resonated deeply with me, particularly because his trajectory with Adjacent so closely reflects our own. As a solo General Partner, Nico has carved a unique and forward-thinking path in venture capital. His strong conviction and belief in the disruptive potential of consumer subscription apps align perfectly with how we see the world. His portfolio, featuring iconic consumer companies like Revolut, Calm, Niantic, Backbone, PhotoRoom, and BeReal only made it clearer that we needed to find a way to collaborate.</p>
                  <p>Nico's investment in Copilot places him alongside a special group of early-stage funds that have always believed in us such as Ludlow, Restive, Shrug, Path Ventures, and Exponent Founders Capital. Additionally, we're thrilled to share an exceptional lineup of operators and startup founders, including Scott Belsky (CPO at Adobe), Mathilde Collin (co-founder at Front), Immad Akhund (co-founder at Mercury), Andrew Farah (co-founder at Density), Ryan Delk (co-founder at Primer), Neil Parikh (co-founder at Casper), Jeff Weinstein (Product at Stripe), Lenny Rachitsky (former Growth PM Lead at Airbnb), Brian Lovin (co-founder at Campsite), and many more, who have also joined our journey.</p>
                  <H5>Let's build the future of money management, together</H5>
                  <p>As we celebrate this milestone, I need to take a moment to express our deepest gratitude to our users. Your belief in our vision has been the foundation of our journey. Honestly, we wouldn't be where we are today without your unwavering support. There were times when raising capital for Copilot was challenging, yet it was your commitment, demonstrated through your subscriptions, that kept us afloat and inspired.</p>
                  <p>Our pledge to you remains the same. Every day, our team's only focus is to improve Copilot to better serve your needs. Your financial goals are at the heart of everything we do, and we can't wait for you to see what we have in store for the future.</p>
                  <p>If our story resonates with you, and you believe that well-crafted software can empower more people to be in control of their finances, join us! We now have a few positions available for folks based in NYC and Santiago. Take a look at our new <OutboundLink href="https://careers.copilot.money">careers page</OutboundLink>, and feel free to reach out!</p>
                  <br/>
                  <p>Andres Ugarte<br/><Muted>Founder & CEO</Muted></p>
                </SeriousTalk>
            </Grid>
        </Section>
        <Footer/>
    </Layout>
  )
}

const Muted = styled.span`
    color: ${props => props.theme.color.textSecondary};
`

const HeaderImage = styled.div`
    grid-column: col-start / span 12;
    width: 100%;
    margin-bottom: 2rem;
    ${props => props.theme.device.tablet} {
      grid-column: 3 / span 8;
    }
    ${props => props.theme.device.laptop} {
      grid-column: 4 / span 6;
    }
`

const ChartImage = styled.div`
    margin-bottom: 2rem;
`

export default SeriesAAnnouncementPage
